// @flow

import React from "react";
import {
    AssignmentLayout,
    Layout,
    AssignmentHistory,
    withPrivateRoute,
} from "@containers";

/**
 * Reactie
 */
const History = (props: *) => (
    <Layout {...props}>
        <AssignmentLayout title="Historiek">
            <AssignmentHistory />
        </AssignmentLayout>
    </Layout>
);

export default withPrivateRoute(History, "/opdrachten/:inspectionId/historiek");
